@import "src/assets/css/variables.scss";
$card-width: 308px;
$card-min-width: 308px;
$card-height: 16rem;
$card-compact-height: 115px;
$card-padding-x: 16px;
$card-padding-y: 24px;
$inner-card-width: $card-width - 2 * $card-padding-x;

.card-service-main-container {
  position: relative;
  text-align: center;
  max-width: $card-width;
  min-width: $card-min-width;
  height: $card-height;
  border-radius: 13px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 $card-padding-x/2 $card-padding-y $card-padding-x/2;
  background-color: white;
  box-shadow: 0 0 8px 0 rgba(165, 173, 189, 0.5);
  cursor: pointer;
  padding: 0.5rem 0.987rem 1.375rem 1.013rem;

  @media (max-width: $phone-grid) {
    max-width: 100%;
    width: calc(100% - 32px);
  }

  &.warning {
    border: 3px solid $color-orange;
  }

  &.is-compact {
    height: $card-compact-height;
    max-width: 294px;
    min-width: 294px;
    @media (max-width: $phone-grid) {
      max-width: 100%;
    }
    .notification-service-container {
      margin-top: 12px;
      &.payment,
      &.check,
      &.action {
          top: 0px;
      }
      &.message {
          top: 48px;
      }
      &.internal {
        top: 48px;
        right: -4px;
        left: unset;
        border-top-left-radius: 18px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 18px;

        .notification-icon-box {
          border-top-right-radius: 0;
          border-top-left-radius: 50%;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 50%;
        }

        .notification-info-text {
  
          &.notiOpened {
              width: auto;
          }
  
          .text-box {
              width: 100%;
              height: 32px;
              margin-top: 4px;
              margin-bottom: 4px;
              flex-direction: column;
              box-shadow: inset -4px 0px 8px -4px rgba(165, 173, 189, 0.8);
              .regular {
                  color: $color-black;
                  font-size: 12px;
                  font-family: $font-regular;
              }
              .link-container {
                  font-size: 14px;
              }
          }
      }

      }
    }
  }

  .card-corner-container {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    .review-container {
      width: 100%;
      padding-top: 5px;
      padding-right: 3px;
      .review-value {
        font-family: SourceSansPro-Bold;
        font-size: 12px;
        color: $color-blue-dark;
        padding-right: 2px;
        margin-top: -1px;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }

  .card-service-image-container {
    position: relative;
    width: auto;
    height: 100%;
    max-width: 200px;
    max-height: 100px;
    margin: 0 auto;
    background-color: white;
    padding: 4px 0;
    > img {
      width: 100%;
      height: 100%;
      max-height: 100px;
      object-fit: contain;
    }
    &.details {
      width: auto;
      height: 144px;
      max-width: 100%;
      max-height: 144px;
      background-color: white;
      
      > img {
        width: 100%;
        height: 100%;
        max-height: 144px;
        object-fit: contain;
      }

      .share-button-container {
        position: absolute;
        bottom: -12px;
        right: 4px;
        .share-icon {
            width: 24px;
            height: 24px;
            padding: 4px;
            box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.7);
            background-color: white;
            border-radius: 50%;
            > img {
              width: 16px;
              height: 16px;
              object-fit: contain;
            } 
          }
        }
      }
  }

  .details-page {
    .card-service-state-container > .card-service-info-container >
    .service-equipment-data {
      height: auto;
    }
  }
 }

.card-service-state-container {
  width: 100%;
  position: relative;

  .card-service-info-container {
    width: 100%;
    position: relative;

    .service-equipment-data {
      width: 100%;
      display: inline-flex;
      text-align: center;
      height: 22px;

      .service-equipment-data-row {
        margin: 1px 0px 0px 20px;
        display: flex;
        align-items: flex-start;
        width: 50%;
        //justify-content: center;

        img {
          width: 16px;
          max-height: 16px;
        }
     //   &.flexRight{
          //justify-content: flex-end;
     //   }
      }
    }

    .service-information-container {
      display: inline-flex;
    }
  }

  &.details {
    .card-service-info-container {
      .service-equipment-data {
        text-align: left;

        .service-equipment-data-row {
          width: 50%;
        }
      }
    }
  }
}

.service-equipment-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .service-equipment-name {
    color: $color-black;
    font-size: 18px;
    line-height: 1.25;
    font-family: SourceSansPro-Semibold;
    width: 100%;
  }
  .service-equipment-id {
    color: $color-black;
    font-size: 16px;
    line-height: 1.25;
    font-family: "SourceSansPro-Light";
    width: 100%;
  }
  &.details {
    .service-equipment-name {
      font-size: 22px;
    }
  }
}

.service-date-text {
  padding: 0px;
  margin: auto 4px;
  color: $color-black;
  font-size: 1rem;
  font-family: "SourceSansPro-Regular";
  line-height: 1rem;
  &.tbd {
    font-family: "SourceSansPro-Light";
  }
  &.active {
    color: $color-green;
  }
  .service-date-estimated-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    line-height: 1;
    letter-spacing: normal;
    .estimated-date-label {
      font-family: SourceSansPro-Regular;
      color: $color-grey-blue;
      font-size: 12px;
    }
    .estimated-date-text {
      font-family: SourceSansPro-Regular;
      font-size: 1rem;
    }
  }
}

.card-service-location {
  display: inline-flex;
  align-items: center;
  .card-service-location-text {
    font-size: 16px;
    @media (max-width: $phone-grid) {
      font-size: 14px;
    }
  }
  > img {
    width: 24px;
    height: 24px;
  }
}

.extraMargin {
  margin: 5px auto;
}

.card-service-details-info-container {
  .service-equipment-button-container {
    .service-equipment-button {
      left: 0px;
    }
  }
}

.service-equipment-button-container {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -16px;
  width: 100%;
  left: 0px;

  .service-equipment-button {
    display: flex;
    text-align: center;
    width: 32px;
    .button-oval {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      text-align: center;
      box-shadow: 1px 2px 3px 0 $color-grey-blue;
      border: solid 1px $color-blue-dark;
      background-image: linear-gradient(
        to bottom,
        $color-blue,
        $color-blue-dark
      );
      &.black {
        border: none;
        box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.4);
        background-image: linear-gradient(
          to bottom,
          $color-indigo,
          $color-indigo-dark 54%,
          $color-black
        );
      }
      > img {
        filter: $filter-white;
      }
    }
  }
  .service-equipment-button img,
  i {
    display: flex;
    text-align: center;
    margin: auto;
    width: 24px;
  }

  .service-checkout-date {
    display: flex;
    text-align: center;
    margin: auto;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    font-family: "SourceSansPro-Regular";
    position: relative;
    left: 20px;
    top: 24px;
  }
}

.service-equipment-categories {
  width: 58%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}

.category-icon-container {
  width: 20px;
  height: 20px;
  position: relative;
  margin: 3px;
  float: left;
  text-align: center;
}

.timelinettest {
  width: 220px;
  height: 10px;
  margin: 5px auto;
  border-radius: 5px;
  background-color: $color-blue;
  box-shadow: 0px 2px 14px rgba(0, 169, 253, 0.5);
}

.card-service-feedback-container {
  position: absolute;
  width: calc(100% - 32px);
  height: 114px;
  border-radius: 8px;
  box-shadow: 0 1px 6px 1px rgba(165, 173, 189, 0.6);
  text-align: cnter;
  padding: 4px 16px 8px;
  bottom: 24px;
  background-color: white;
  &.no-card {
    height: auto;
    box-shadow: none;
    padding: 0;
    position: unset;
  }
}

.text-area-container {
  display: flex;
  justify-content: center;
  align-items: center;
  textarea {
    &:focus,
    &:focus-active,
    &:active {
      border: 1px solid $color-orange !important;
    }
  }
  .text-area-feedback {
    position: relative;
    width: auto;
    border-radius: 8px;
    border-width: 0px;
    box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.4);
    background-color: white;
    padding: 6px 48px 6px 12px;
    font-family: "SourceSansPro-Regular";
    font-size: 14px;
    color: $color-black;

    &.small {
      height: 32px;
      resize: none;
    }
    &.medium {
      width: 100%;
      height: auto;
      min-height: 100px;
      button: {
        right: 16px;
      }
    }
    &:focus {
      font-size: 16px; /* Revert to 14px on focus */
    }
  }
}

.card-cta-container {
  height: 24px;
}

.cta-show-feedback {
  padding: 2px 8px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 2px 4px 1px rgba(165, 173, 189, 0.75);
  background-image: linear-gradient(to bottom, white 50%, $white);
  font-family: SourceSansPro-SemiBold;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $color-orange;
  img {
    width: 12px;
    height: 12px;
  }
}

.feedback-cta-container {
  position: absolute;
  bottom: 10px;
  right: 16px;
  width: auto;
  @media (max-width: $phone-grid) {
    width: calc(100% - 32px);
  }
}

.card-service-feedback-text {
  font-size: 14px;
  font-family: "SourceSansPro-Regular";
  line-height: 1.29;
}

.rating-container.shinning .rate-service svg:nth-child(1) path:last-child {
  animation: selectStars 0.8s ease-in-out 0s forwards;
}
.rating-container.shinning .rate-service svg:nth-child(2) path:last-child {
  animation: selectStars 0.8s ease-in-out 0.1s forwards;
}
.rating-container.shinning .rate-service svg:nth-child(3) path:last-child {
  animation: selectStars 0.8s ease-in-out 0.2s forwards;
}
.rating-container.shinning .rate-service svg:nth-child(4) path:last-child {
  animation: selectStars 0.8s ease-in-out 0.3s forwards;
}
.rating-container.shinning .rate-service svg:nth-child(5) path:last-child {
  animation: selectStars 0.8s ease-in-out 0.4s forwards;
}

@keyframes selectStars {
  0% {
    fill: none;
  }
  50% {
    fill: #0196E0;
  }
  100% {
    fill: none;
  }
}

.rating-container {
  display: flex;
  margin: auto;
}

.rate-service {
  margin: auto;
}
.rate-service:not(:checked) > input {
  display: none;
}
.rate-service:not(:checked) > label {
  float: right;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: rgb(165, 165, 165);
}

.rate-service:not(:checked) > label:before {
  content: "★ ";
}
.rate-service > input:checked ~ label {
  color: #ffffff;
}
.rate-service:not(:checked) > label:hover,
.rate-service:not(:checked) > label:hover ~ label {
  color: #ffffff;
}
.rate-service > input:checked + label:hover,
.rate-service > input:checked + label:hover ~ label,
.rate-service > input:checked ~ label:hover,
.rate-service > input:checked ~ label:hover ~ label,
.rate-service > label:hover ~ input:checked ~ label {
  color: #ffffff;
}
