@import 'src/assets/css/variables.scss';
.button-main-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    &.next-row {
        flex-basis: 100%;
        // height: 0;
    }
}

.smallButtonContainer{
    width: 37px;
    height: 35px;
    display: flex;
    margin: 10px auto auto -20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: $color-blue;
    cursor: pointer;
}

.inside-button-container {
    position: absolute;
    right: 0;
    width: 32px;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    &.password {
        height: 32px;
        bottom: 0;
    }
}

.inside-textarea-button-container {
    position: absolute;
    right: 36px;
    width: 24px;
    height: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    padding: 4px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.7);
    bottom: 40px;

    &.loading {
        box-shadow: none;
        background-color: unset;
    }

    @media (max-width: $tablet-grid) {
        right: 30px;
        bottom: 50px;
    }
}

.icon-container {
    width: 16px;
    height: 50%;
    margin: auto;
    i.fa {
        color: $color-blue;
        font-size: 16px;
    }

    &.icon-inactive {
        filter: $filter-grey-blue;
        &:focus, &:hover, &:active {
            filter: none;
        }
    }
}

.submit-container {
    position: relative;
    width: 100%;
    max-width: 275px;
    height: 38px;
    align-items: center;
    justify-content: center; 
    border-radius: 19px;
    border: 1px solid $color-blue;
    background-color: $color-blue;
    box-shadow: 1px 4px 12px $color-blue;
    color: #FFFFFF;
    font-size: 17px;
    font-family: 'SourceSansPro-Regular';
    cursor: pointer;
    &:hover, &:focus {
        outline: unset;
    }
}

.submit-container-alt {
    width: 250px;
    height: 38px;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 19px;
    border: 1px solid $color-blue;
    background-color: $color-blue;
    box-shadow: 1px 4px 12px $color-blue;
    color: #FFFFFF;
    font-size: 17px;
    font-family: 'SourceSansPro-Regular';
    cursor: pointer;

    &.inv {
        border: 1px solid $color-blue;
        background-color: white;
        box-shadow: none;
        color:  $color-blue;
    }
}

.cta-multibutton-secondary{
    width: auto;
    min-width: 75px;
    max-width: 277px;
    margin: auto;
    min-height: 32px;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    line-height: 18px;
    border: solid 1px $color-grey-blue;
    background-color: white;
    color: $color-black;
    font-family: $font-semibold;
    letter-spacing: normal;
    img {
        width: 16px;
        height: 16px;
    } 
  }

.cta-button-secondary {
    width: auto;
    min-width: 75px;
    max-width: $max-column-width;
    margin: auto;
    min-height: 32px;
    font-size: 14px;
    text-align: center;
    border-radius: 14px;
    border: 2px solid $color-blue-dark;
    background-color: transparent;
    color: $color-blue-dark;
    font-family: 'SourceSansPro-Semibold';

    @media (max-width: $tablet-grid) {
        max-width: $max-column-width-mobile;
    }
        
    &.shadow {
        box-shadow: 1px 2px 3px 0 $color-grey-blue;
    }
    &.short {
        padding: 4px 16px;
        min-width: auto;      
    }
    &:hover,
    &.shinning {
        background-color: $color-blue-dark;
        color: white;
        .icon-tint-dynamic {
            filter: invert(100%) saturate(100%) brightness(1000%) contrast(100%);
          }
    }
    .icon-tint-dynamic {
        width: 16px;
        height: 16px;
        filter: $filter-blue-dark;    
    }
    &.white {
        background-color: white;
        box-shadow: 1px 2px 3px 0 rgba(165, 173, 189, 0.7);
        &:hover {
            background-color: $color-blue-dark;
            color: white;
            box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
            .icon-tint-dynamic {
                filter: $filter-white;
            }
        }
    }
    &.d-sm-large {
        @media (min-width: $phone-grid) {
            border-radius: 18px;
        }
    }
}

.cta-button-primary {
    width: auto;
    min-width: 75px;
    max-width: $max-column-width;
    margin: auto;
    min-height: 32px;
    font-size: 14px;
    text-align: center;
    border-radius: 14px;
    box-shadow: 1px 2px 3px 0 $color-grey-blue;
    border: solid 1px $color-blue-dark;
    background-image: linear-gradient(to bottom, $color-blue, $color-blue-dark);
    color: white;
    font-family: 'SourceSansPro-Semibold';
    @media (max-width: $tablet-grid) {
        max-width: $max-column-width-mobile;
    }
    &.shinning {
        outline: 3px solid $color-orange;
        width: calc(100% - 6px);
        margin: auto;
        transition: outline 0.3s ease, width 0.3s ease // Add transitions for smooth effect
    }
    & {
        outline: 0 solid transparent;
        width: 100%;
        transition: outline 0.3s ease, width 0.3s ease // Same transition as above
    }
    &.disabled {
        opacity: 0.5;
    }
    &.d-sm-large {
        @media (min-width: $phone-grid) {
            border-radius: 18px;
        }
    }
    &.mediumWidth {
        min-width: 180px;
    }
}

.cta-button-sign-in-provider {
    width: auto;
    min-width: 75px;
    max-width: $max-column-width;
    margin: auto;
    min-height: 32px;
    font-size: 14px;
    text-align: center;
    border-radius: 8px;
    line-height: 18px;
    border: solid 1px $color-grey-blue;
    background-color: white;
    color: $color-black;
    font-family: $font-semibold;
    letter-spacing: normal;
    img {
        width: 16px;
        height: 16px;
    } 
}

.divider-container {
    width: 100%;
    max-width: $max-column-width;
    margin: auto;
    .divider-text {
        font-family: $font-light;
        font-size: $font-size-regular;
    }
    .line-divider {
        width: 100%;
        border-top: 1px solid $color-blue-extra-light;
        height: 1px;
    }
}

.submit-btns-container {
    width: 100%;
    margin-bottom: 40px;
}

.submitDeclineContainer {
    max-width: 275px;
    width: 90%;
    height: 38px;
    align-items: center;
    justify-content: center;
    // margin-top: 50px;
    // margin-bottom: 30px;
    border-radius: 19px;
    border: 1px solid #F06767;
    background-color: #F06767;
    box-shadow: 1px 4px 12px #F06767;
    color: #FFFFFF;
    font-size: 17px;
    font-family: 'SourceSansPro-Regular';
    cursor: pointer;
    
}

.reset-details-button {
    max-width: 275px;
    width: 90%;
    height: 38px;
    align-items: center;
    justify-content: center;
    border-radius: 19px;
    border: 1px solid $color-blue;
    background-color: $color-blue;
    box-shadow: 1px 4px 12px $color-blue;
    color: #FFFFFF;
    font-size: 17px;
    font-family: 'SourceSansPro-Regular';
    cursor: pointer;
}

.submitCancelContainer {
    width: 250px;
    height: 38px;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 20px;
    border-radius: 19px;
    border: 1px solid #FFB002;
    background-color: #FFB002;
    box-shadow: 1px 4px 12px #FFB002;
    color: #FFFFFF;
    font-size: 17px;
    font-family: 'SourceSansPro-Regular';
    cursor: pointer;
}

.submitTitle {
    font-family: 'SourceSansPro-Regular';
    font-size: 17px;
    color:#FFFFFF;
}

.button-container {
    width: 250px;
    height: 38px;
    align-items: center;
    justify-content: center;
    // margin-top: 35px;
    // margin-bottom: 20px;
    margin-left: 20px; 
    margin-right: 20px; 
    border-radius: 19px;
    border: 1px solid $color-blue;
    background-color: #FFFFFF;
    color: $color-blue;
    font-size: 17px;
    font-family: 'SourceSansPro-Regular';
    cursor: pointer;
}

.button-container-inverted {
    max-width: 250px;
    width: 100%;
    height: 38px;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: 19px;
    border: 1px solid $color-blue;
    background-color: transparent;
    color: $color-blue;
    font-size: 17px;
    font-family: 'SourceSansPro-Regular';
    cursor: pointer;
}

.button-container-inverted:hover {
    background-color: $color-blue;
    color: #FFFFFF
}

.link-container-span,
.link-container,
.link-container > a,
.link-container > p,
.link-container > span {
    font-family: 'SourceSansPro-Regular';
    font-size: 14px;
    color:  $color-blue !important;  
    cursor: pointer;
    text-decoration: underline $color-blue;

    img {
        width: 16px;
        height: 16px;
    }
}

.approve-button-container {
    position: relative;
    width: 100%;
    height: 32px;
    align-items: center;
    justify-content: center; 
    border-radius: 10px;
    border: 1px solid $color-green;
    background-color: $color-green;
    color: $text-white;
    font-size: 17px;
    font-family: 'SourceSansPro-Regular';
    cursor: pointer;
    &:hover, &:focus {
        outline: unset;
    }
}

.notification-service-container {
    z-index: 1;
    left: -4px;
    position: absolute;
    margin-top: 0;
    height: 40px;
    box-shadow: -1px 2px 8px 0 rgba(165, 173, 189, 0.8);
    background-color: white;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
 
    &.check,
    &.payment,
    &.action {
        top: 40px;
    }
    &.message {
        top: calc(40px + 48px);
    }
    &.internal {
        top: calc(40px + (2 * 48px));
    }
    &.check.details,
    &.payment.details,
    &.action.details {
        margin-top: 16px;
        top: 0;
    }
    &.message.details {
        margin-top: 16px;
        top: 48px;
    }
    &.internal.details {
        margin-top: 16px;
        top: calc(48px + 48px);
    }
    .notification-info-text {
        z-index: 2;
        width: 0;
        height: 40px;
        background-color: white;
        color: $color-black;
        font-size: 12px;
        line-height: 16px;
        font-family: $font-bold;

        &.notiOpened {
            width: auto;
        }

        .text-box {
            width: 100%;
            height: 32px;
            margin-top: 4px;
            margin-bottom: 4px;
            flex-direction: column;
            box-shadow: inset -4px 0px 8px -4px rgba(165, 173, 189, 0.8);
            &.message {
                background-color: $color-yellow-light;
            }
            &.check {
                background-color: $color-green-light;
            }
            &.payment,
            &.action {
                background-color: $color-red-light;
            }
            &.internal {
                background-color: $color-blue-light;
            }
            .regular {
                color: $color-black;
                font-size: 14px;
                font-family: $font-regular;
            }
            .link-container {
                font-size: 14px;
            }
        }
    }
    
    .notification-icon-box {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: white;
        > img {
            width: 24px;
            height: 24px;
        }
      }
}


.d-medium {
    @media (min-width: $tablet-grid) {
        min-height: 32px ;
        font-size: 14px;
    }
}
.d-md-medium {
    @media (min-width: $tablet-grid) {
        min-height: 32px;
        font-size: 14px;
    }
}
.d-sm-medium {
    @media (min-width: $phone-grid) {
        min-height: 32px;
        font-size: 14px;
    }
}
.d-lg-medium {
    @media (min-width: $desktop-grid) {
        min-height: 32px;
        font-size: 14px;
    }
}
.d-large {
    @media (min-width: $tablet-grid) {
        min-height: 40px;
        font-size: 16px;
    }
}
.d-md-large {
    @media (min-width: $tablet-grid) {
        min-height: 40px;
        font-size: 16px;
    }
}
.d-sm-large {
    @media (min-width: $phone-grid) {
        min-height: 40px;
        font-size: 16px;
    }
}
.d-lg-large {
    @media (min-width: $desktop-grid) {
        min-height: 40px;
        font-size: 16px;
    }
}

.spinner {
    border: 2px solid rgba(163, 163, 163, 0.8);
    border-top: 2px solid #006a9f;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 0.8s linear infinite;
    margin-left: 8px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.text-multiline-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1.5;
    max-height: 3.3em;
    word-break: break-word;
    overflow-wrap: anywhere;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
}