@import 'src/assets/css/variables.scss';
.symptoms-row-container {
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-direction: column; // Stack children vertically
    margin: 1px auto auto;
    border-radius: 4px;
    align-items: center;
    list-style-type: none;
    background-color: transparent;
    //justify-content: space-between;
    position: relative;
    padding: 0 0px 0 0px;
     
    &:nth-child(odd) {
        background-color: $color-blue-extra-light;
    }
    &:nth-child(even) {
        background-color: rgba($color-grey-blue, 0.25);
    }
    &:hover,
    &:active,
    &.active {
      box-shadow: 0 2px 8px 0 rgba(165, 173, 189, 0.7);
    }

    .row-content {
        cursor: pointer;
        width: 100%;
        display: flex;
        flex-direction: row; // Keep the original row behavior for these items
        align-items: center;
        justify-content: flex-start;  // Align children to the start (left)
        padding-left: 16px;

    }
    @media (max-width: $tablet-grid) {
        &:hover {
            box-shadow: none; // Removes the box-shadow on hover, active states
        }
      }
}

.symptoms-row-text {
    font-family: $font-regular;
    font-size: 17px;
    font-weight: bold;
    color: $color-black;
    text-transform: capitalize;
    padding: 0 0 0 16px;

    &.inner-html {
        text-transform: none;
    }
    &.discount {
        font-family: $font-semibold;
        color: $color-orange;
        > span {
            font-family: $font-semibold;
            color: $color-orange; 
        }
    }
    .symptoms-row-status {
        width: 24px;
        height: 24px;
        img {
            width: 24px;
            height: 24px;
        }
    }
}

.observations-container {
    display: flex;
    flex-direction: column; // Align children (label and input) in a column
    align-items: flex-start; // Align children to the start of the flex container (left)
    margin-top: 2px; // Provides a top margin for spacing as per your class mt-2
    width: 100%; // Ensures the container takes full width
    padding-left: 16px;

    &.wide {
        padding-left: 0px;
    }

    .observations-label {
        margin-bottom: 4px; // Provides space between the label and the input
    }

    input.km-text-area {
        width: 100%; // Ensures the input stretches to fill the container
        max-width: 308px;
        padding: 8px; // Padding inside the input for text alignment
        font-size: 14px; // Ensures text size is legible
        border-radius: 8px;
        border-width: 0px;
        box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.4);
        background-color: white;
        padding: 6px 36px 6px 12px;
        &::placeholder {
            /* Standard */
            color: $color-grey-blue;
        }
        &.active {
            border: 1px solid $color-blue-light;
        }
        &:focus {
            font-size: 16px;
            /* Revert to 14px on focus */
        }
    }
}
