@import "src/assets/css/variables.scss";

$service-card-x: 149px;
$service-card-y: 96px;
$service-card-container: 652px;
$step-summary-container: 652px;

$action-field-container-small: 340px;
$action-field-container-large: 427px;
$action-field-container-xl: 652px;
$slot-time-card: 73px;

.schedule-page-files-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 652px;
  width: 100%;
  min-width: 300px;
}

.schedule-page-container {
  text-align: center;
  width: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 72px);

  @media (max-width: $tablet-grid) {
    overflow-y: hidden;
    max-height: 100%;
  }

  .top-information-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .schedule-service-top-info-list-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 26.688rem;
    overflow: hidden auto;
    border-radius: 0 0 8px 8px;
    padding-top: 4px;
    background-color: rgba($color-blue-light, 0.5);
    max-height: 200px;
    border-style: solid;
    border-color: rgba($color-blue-light, 0.1);
    border-width: 0 0 4px 0;

    max-height: 0;
    opacity: 0;
    transition: max-height 0.4s ease-in-out, opacity 0.4s ease-in-out;
    
    &.open {
      max-height: 200px;
      opacity: 1;
      box-shadow: inset 0px 5px 4px -3px rgba(0, 0, 0, 0.2);
    }
  }

  .schedule-service-top-info-container {
    display:flex;
    width: 100%;
    max-width: 26.688rem;
    overflow: hidden;
    height: 4.25rem;
    padding: 0.25rem 0.25rem 0.5rem 0.25rem;
    border-radius: 8px 8px 0 0;
    background-color: rgba($color-blue-light, 0.5);

    &.selected {
      background-color: $color-blue-light;
    }
    &.list {
      border-radius: 8px;
      margin: 2px;
      width: 98%;
      height: 65px;
      min-height: 65px;
      cursor: pointer;
      
      &:hover {
        background-color: rgba($color-blue-light, 0.9);
      }
    }

  }

  .schedule-service-top-info-container.add {
    flex-direction: column;
    height: auto;
    min-height: fit-content;
    padding: 0.5rem 0.75rem 0.25rem 0.75rem;
    cursor: unset;

    &.notLiteNoEquip {
      border-radius: 8px;
    }

    &:hover {
      background-color: rgba($color-blue-light, 0.5);
    }
    .vehicle-title {
      width: 17rem;
      height: 1.125rem;
      //margin: 0 2.75rem 1.875rem 0;
      font-family: $font-semibold;
      font-size: 0.875rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $color-black;
      text-align: left;
      margin-bottom: 0.7rem;
      text-wrap-mode: nowrap;
    }
    .equipment-inputs {
      input {
        display: block;
        margin-bottom: 0.5rem;
        text-align: left;
        font-family: $font-regular;
        width: 100%;
        height: 1.5rem;
        border-radius: 8px;
        box-shadow: 1px 0 8px 0 rgba(165, 173, 189, 0.4);
        background-color: $color-white;
        border-style: none;
        padding-left: 8px;
      }
    }
  }

  .schedule-service-top-select-equipment {
    display:flex;
    width: 100%;
    height: 1.5rem;
    max-width: 26.688rem;
    overflow: hidden;
    border-radius: 0 0 8px 8px;
    background-color: $color-blue-light;
    text-align: center;
    font-size: 0.9rem;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    &.button {
      cursor: pointer;
    }

    &.open {
      background-color: rgba($color-blue-light, 0.5);
      border-radius: 0;
    }
  }
  .schedule-service-top-image-container {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 3.5rem;
    margin: 0 0.5rem 0 0;
    padding: 0.1rem;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(165, 173, 189, 0.7);
    background-color: $color-white;
  }

  .schedule-service-top-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  .schedule-service-top-info{
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .schedule-service-top-title{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.438rem 0.5rem 0.25rem 0;
    font-family: $font-regular;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: $color-black;
    text-align: left;
  }

  .schedule-service-top-subtitle{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.25rem 0.5rem 0.438rem 0;
    font-family: $font-semibold;
    font-size: 1rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $color-black;
    text-align: left;
  }

  .schedule-service-description-container {

    &.empty {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: $action-field-container-large;
      width: 100%;
      height: 24px;
      padding: 2px 12px;
      border-radius: 8px;
      background-color: rgba($color-blue-light, 0.5);
      color: $color-black;
    }

    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    max-width: $action-field-container-large;
    width: 100%;
    height: 24px;
    padding: 2px 12px;
    border-radius: 8px;
    background-color: rgba($color-blue-light, 0.5);
    color: $color-black;

   // @media (max-width: $tablet-grid) {
   //   max-width: $action-field-container-small;
   // }
    .service-description-text {
      font-family: $font-regular;
      font-size: $font-size-regular;
      line-height: 16px;
      white-space: nowrap;
      margin-right: 8px;
    }

    .service-param-info {
      font-family: $font-semibold;
      font-size: $font-size-medium;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .steps-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    // max-width: $action-field-container;
    width: 100%;
    height: 24px;

    .step {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin: 0 12px 0 0;
      border: solid 2px $color-blue-dark;
      background-color: white;
      border-radius: 50%;
      font-family: $font-semibold;
      font-size: $font-size-medium;
      color: $color-black;
      opacity: 1;

      &.active {
        background-color: $color-blue-dark;
        color: white;
      }

      &.disabled {
        opacity: 0.15;
      }
    }
  }

  .step-action-container {
    width: 100%;

    .step-description-container {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: $color-black;
      font-size: $font-size-medium;

      .step-label {
        font-family: $font-semibold;
      }

      .step-description {
        font-family: $font-light;
        &.strong {
          font-family: $font-semibold;
        }
      }
    }
  }

  .step-action-field-content {
    max-width: $action-field-container-large;
    margin: auto;

    @media (max-width: $tablet-grid) {
      max-width: $action-field-container-small;
    }

    .slots-grid-container  {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax($slot-time-card, 1fr));
      /* Adjust the column width as needed */
      column-gap: 8px;
      row-gap: 8px;
      /* Adjust the gap between grid items */
      justify-content: space-between;

      .slot-card {
        width: $slot-time-card;
        height: 24px;
        background-color: $color-blue-extra-light;
        color: $color-black;
        font-size: 1rem;
        font-family: $font-regular;
        text-align: center;

        &.active {
          font-family: $font-semibold;
          font-size: 1rem;
          color: $color-blue;
          box-shadow: 0 2px 8px 0 rgba(165, 173, 189, 0.7);
        }

        &:hover, &:active, &:focus {
          box-shadow: 0 2px 8px 0 rgba(165, 173, 189, 0.7);
        }
      }
    }
  }

  .step-action-content {
    max-width: $service-card-container;
    width: 100%;
    min-width: 300px;
    display: inline-flex;

    &.grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax($service-card-x, 1fr));
      /* Adjust the column width as needed */
      column-gap: 16px;
      row-gap: 16px;
      /* Adjust the gap between grid items */
      justify-content: space-between;
    }

    .step-summary-container {
      width: 100%;
      max-width: $step-summary-container;


    }

    .schedule-service-card {
      width: $service-card-x;
      height: 100%;
      min-height: $service-card-y;
      margin: auto;
      background-color: $color-blue-extra-light;
      border-radius: 13px;
      padding: 8px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .schedule-service-name {
        text-align: center;
        color: $color-black;
        font-family: $font-regular;
        line-height: 18px;
        font-size: $font-size-regular;
      }

      &:hover,
      &:active,
      &.active {
        box-shadow: 0 2px 8px 0 rgba(165, 173, 189, 0.7);

      }
      &.active {
        .schedule-service-name {
          color: $color-blue;
        }
      }
      &.disabled {        
        .schedule-service-name {
          text-align: center;
          color: $color-grey-dark;
          font-family: $font-regular;
          line-height: 18px;
          font-size: $font-size-regular; 
        }
      }
    }
   // @media (max-width: $tablet-grid) {
   //   max-width: $action-field-container-small;
   // }
  }

  .step-action-observations-container {
    max-width: $action-field-container-xl;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: auto;

    .observations-label {
      text-align: start;
    }
    .observations-symptoms-label {
      text-align: start;
    }
    @media (max-width: $tablet-grid) {
      max-width: $action-field-container-small;
    }
  }

  .cta-buttons-container {
    width: 100%;
    max-width: $action-field-container-large;
    display: inline-flex;
    justify-content: center;
    margin: auto;
    .cta-button-secondary,
    .cta-button-primary {
      max-width: 100%;
    }
    @media (max-width: $tablet-grid) {
      max-width: $action-field-container-small;
    }
  }

  .symptom-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .bullet-point {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $color-indigo;
    }

    .symptom-name {
      font-size: $font-size-medium;
      font-family: $font-light;
      text-align: left;
    }
  }
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}