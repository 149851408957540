@import 'src/assets/css/variables.scss';
.tasks-row-container {
    width: 100%;
    min-height: 40px;
    display: flex;
    margin: 1px auto auto;
    border-radius: 4px;
    align-items: center;
    list-style-type: none;
    background-color: transparent;
    justify-content: space-between;
    position: relative;
    padding: 0 8px 0 16px;
    
    &:nth-child(odd) {
        background-color: $color-blue-extra-light;
    }
    &:nth-child(even) {
        background-color: rgba($color-grey-blue, 0.25);
    }

    &.observation {
        background-color: $color-yellow-light;
        &.shinning {
            outline: 3px solid $color-orange;
            width: calc(100% - 6px);
            margin: auto;
            transition: outline 0.3s ease, width 0.3s ease // Add transitions for smooth effect
        }
        & {
            outline: 0 solid transparent;
            width: 100%;
            transition: outline 0.3s ease, width 0.3s ease // Same transition as above
        }
    }
    
}
.tasks-row-text {
    font-family: $font-regular;
    font-size: $font-size-regular;
    color: $color-black;
    text-transform: capitalize;
    &.inner-html {
        text-transform: none;
    }
    &.discount {
        font-family: $font-semibold;
        color: $color-orange;
        > span {
            font-family: $font-semibold;
            color: $color-orange; 
        }
    }

    .tasks-row-status {
        width: 24px;
        height: 24px;
        img {
            width: 24px;
            height: 24px;
        }
    }
}
